import React, { useState } from 'react';

interface SubCategory {
    title: string;
    subItems: string[];
}

interface MenuItem {
    id: number;
    title: string;
    categories: SubCategory[];
}

export const menuItems: MenuItem[] = [
    {
        id: 1,
        title: "newborn clothing",
        categories: [
            {
                title: "new arrivals",
                subItems: ["0-3 months", "3-6 months", "6-9 months"]
            },
            {
                title: "our best sellers",
                subItems: ["0-3 months", "3-6 months", "6-9 months"]
            },
            {
                title: "shop by age",
                subItems: ["0-3 months", "3-6 months", "6-9 months", "9-12 months", "12-18 months", "18-24 months"]
            },
            {
                title: "shop by brand",
                subItems: ["mothercare", "cheekee munkee"]
            }
        ]
    },
    {
        id: 2,
        title: "toddler clothing",
        categories: [
            {
                title: "new arrivals",
                subItems: ["2-3 years", "3-4 years", "4-5 years", "5-6 years"]
            },
            {
                title: "best sellers",
                subItems: ["2-3 years", "3-4 years", "4-5 years", "5-6 years"]
            },
            {
                title: "must have colors",
                subItems: ["blue", "red", "green", "yellow"]
            },
            {
                title: "shop by age",
                subItems: ["2-3 years", "3-4 years", "4-5 years", "5-6 years"]
            },
            {
                title: "shop by brand",
                subItems: ["brand A", "brand B", "brand C"]
            }
        ]
    },
    {
        id: 3,
        title: "kids clothing",
        categories: [
            {
                title: "new arrivals",
                subItems: ["6-8 years", "8-10 years", "10-12 years"]
            },
            {
                title: "seasonal picks",
                subItems: ["summer", "winter", "spring", "autumn"]
            },
            {
                title: "back to school",
                subItems: ["uniforms", "shoes", "accessories"]
            },
            {
                title: "shop by age",
                subItems: ["6-8 years", "8-10 years", "10-12 years"]
            },
            {
                title: "shop by brand",
                subItems: ["brand X", "brand Y", "brand Z"]
            }
        ]
    },
    {
        id: 4,
        title: "accessories",
        categories: [
            {
                title: "new arrivals",
                subItems: ["hats", "scarves", "gloves"]
            },
            {
                title: "popular items",
                subItems: ["sunglasses", "watches", "belts"]
            },
            {
                title: "trending now",
                subItems: ["jewelry", "bags", "shoes"]
            }
        ]
    },
    {
        id: 5,
        title: "accessories",
        categories: [
            {
                title: "new arrivals",
                subItems: ["hats", "scarves", "gloves"]
            },
            {
                title: "popular items",
                subItems: ["sunglasses", "watches", "belts"]
            },
            {
                title: "trending now",
                subItems: ["jewelry", "bags", "shoes"]
            }
        ]
    },
    {
        id: 6,
        title: "accessories",
        categories: [
            {
                title: "new arrivals",
                subItems: ["hats", "scarves", "gloves"]
            },
            {
                title: "popular items",
                subItems: ["sunglasses", "watches", "belts"]
            },
            {
                title: "trending now",
                subItems: ["jewelry", "bags", "shoes"]
            }
        ]
    },
    {
        id: 7,
        title: "accessories",
        categories: [
            {
                title: "new arrivals",
                subItems: ["hats", "scarves", "gloves"]
            },
            {
                title: "popular items",
                subItems: ["sunglasses", "watches", "belts"]
            },
            {
                title: "trending now",
                subItems: ["jewelry", "bags", "shoes"]
            }
        ]
    }
];

const MegaMenu: React.FC = () => {
    const [openMenu, setOpenMenu] = useState<number | null>(null);

    const handleMouseEnter = (id: number) => {
        setOpenMenu(id);
    };

    const handleMouseLeave = () => {
        setOpenMenu(null);
    };

    return (
        <nav className="container mx-auto bg-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between items-center h-16">
                    <div className="flex justify-between space-x-8 w-full">
                        {menuItems.map((item) => (
                            <div
                                key={item.id}
                                className="relative"
                                onMouseEnter={() => handleMouseEnter(item.id)}
                                onMouseLeave={handleMouseLeave}
                            >
                                <button className="text-gray-800 font-medium hover:text-gray-900 focus:outline-none">
                                    {item.title}
                                </button>
                                {openMenu === item.id && (
                                    <div className="absolute left-0 mt-2 w-[1500px] max-w-7xl rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                        <div className="py-4 px-6 grid lg:grid-cols-4 gap-4">
                                            {item.categories.map((category, categoryIndex) => (
                                                <div key={categoryIndex} className="min-w-[150px]">
                                                    <h3 className="font-bold text-company mb-1">{category.title}</h3>
                                                    <ul>
                                                        {category.subItems.map((subItem, subIndex) => (
                                                            <li key={subIndex}>
                                                                <a href="#" className="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100">
                                                                    {subItem}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default MegaMenu;
