import React from 'react';

const Cta: React.FC = () => {
    return (
        <section className="container mx-auto mt-12">
            <div className="bg-cta text-white p-20 flex justify-center items-center">
                <h3 className="mr-8 text-5xl font-bold ">Priced To Go</h3>
                <button className="bg-white text-black text-2xl font-extrabold py-4 px-8 rounded">SHOP NOW</button>
            </div>
        </section>
    );
};

export default Cta;
