import React from 'react';

const ageGroups = [
    {
        label: 'NEWBORN (0-12 MONTHS)',
        image: 'https://offspring.co.ke/wp-content/uploads/2024/06/0088.jpeg', // Replace with the actual image path
    },
    {
        label: 'TODDLER (1-3 YEARS)',
        image: 'https://offspring.co.ke/wp-content/uploads/2024/06/00876.jpeg', // Replace with the actual image path
    },
    {
        label: 'KIDS (4+ YEARS)',
        image: 'https://offspring.co.ke/wp-content/uploads/2024/06/005.jpeg', // Replace with the actual image path
    }
];

const Sba: React.FC = () => {
    return (
        <section className="text-center py-4 mt-8">
            <h2 className="text-3xl font-bold mb-8">shop by age</h2>
            <div className="flex justify-center space-x-2">
                {ageGroups.map((group, index) => (
                    <div key={index} className="flex flex-col items-center">
                        <img src={group.image} alt={group.label} className="h-80 w-80 object-cover rounded-lg mb-4" height='20px' />
                        <span className="text-teal-600 font-semibold">{group.label}</span>
                    </div>
                ))}
            </div>
        </section>
    );
};
export default Sba;
