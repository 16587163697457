import React from 'react';
import './App.css';
import LandingPage from "./Pages/Landing";
import Header from "./Components/Header";
import MegaMenu from "./Components/Megamenu";
import Footer from "./Components/Footer";

function App() {
  return (
      <div className="mx-auto">
          <section className="mt-4">
              <Header/>
              <MegaMenu />
          </section>
          <div>
              <LandingPage/>
          </div>
          <Footer />
      </div>
  );
}

export default App;
