import React from 'react';

const BabyRegistry: React.FC = () => {
    return (
        <section className="container mx-auto">
                <div
                    className="xl:p-16 gap-32 p-12 rounded-sm bg-gradient-to-r from-teal-600 to-teal-500 flex  justify-between flex-col-reverse lg:flex-row">
                    <div className="w-full lg:w-2/3">
                        <h2 className="baby-registry text-4xl text-white  mb-7 text-center lg:text-left">Baby Registry</h2>
                        <p className="text-white leading-8 mb-12 text-center lg:text-left">Congratulations on
                            your upcoming arrival!
                            <br/><br/>
                            Prepare your little one with ease by shopping essential baby items from top brands, manage
                            your registry online and enjoy exclusive perks.
                            <br/><br/>
                            Start creating your perfect registry today!</p>
                        <button className="bg-white font-bold p-4">Create Registry</button>
                    </div>
                </div>
        </section>
    );
};

export default BabyRegistry;
