import React from 'react';

const TopBar: React.FC = () => {
    return (
        <div>
            <div className="container mx-auto">
                <p className="text-center text-company">
                    Free delivery for orders above KES 5,000 within Nairobi
                </p>
            </div>
            <div className="bg-gray-50 py-2 w-full">
                <div className="container mx-auto px-4">
                    <div className="flex justify-between items-center">
                        <h1>Contact us</h1>
                        <div className="flex space-x-2">
                            <span>Connect with us</span>

                            <a
                                href="https://www.facebook.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Facebook"
                            >
                                <i className="fab fa-facebook fa-lg text-gray-600 ml-1"></i>
                            </a>
                            <a
                                href="https://www.facebook.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Facebook"
                            >
                                <i className="fab fa-instagram fa-lg text-gray-600 ml-1"></i>
                            </a>
                            <a
                                href="https://www.facebook.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Facebook"
                            >
                                <i className="fab fa-tiktok fa-lg text-gray-600 ml-1"></i>
                            </a>
                            <a
                                href="https://www.facebook.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Facebook"
                            >
                                <i className="fab fa-whatsapp fa-lg text-gray-600 ml-1"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopBar;
