import React, { useState } from 'react';
import ProductCard from './ProductCard'; // Adjust the import path as necessary

interface Product {
    id: number;
    sku: string;
    name: string;
    description: string;
    images: string | null;
    has_variations: boolean;
    variations: any;
    has_varying_price: boolean;
    price: {
        default: number;
    };
    sub_category_id: number;
    published: boolean;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    stock: {
        quantity: number;
    };
}

const dummyProducts: Product[] = [
    {
        "id": 1,
        "sku": "SKU001",
        "name": "Product 1",
        "description": "Description for Product 1",
        "images": "https://offspring.co.ke/wp-content/uploads/2021/05/huggies-little-swimmers-disposable-swim-pants-421238_2048x2048-700x700.jpg",
        "has_variations": false,
        "variations": null,
        "has_varying_price": false,
        "price": {
            "default": 29.99
        },
        "sub_category_id": 1,
        "published": true,
        "created_at": "2024-09-01T00:00:00Z",
        "updated_at": "2024-09-01T00:00:00Z",
        "deleted_at": null,
        "stock": {
            "quantity": 100
        }
    },
    {
        "id": 2,
        "sku": "SKU002",
        "name": "Product 2",
        "description": "Description for Product 2",
        "images": "https://offspring.co.ke/wp-content/uploads/2021/05/huggies-little-swimmers-disposable-swim-pants-421238_2048x2048-700x700.jpg",
        "has_variations": false,
        "variations": null,
        "has_varying_price": false,
        "price": {
            "default": 39.99
        },
        "sub_category_id": 1,
        "published": true,
        "created_at": "2024-09-02T00:00:00Z",
        "updated_at": "2024-09-02T00:00:00Z",
        "deleted_at": null,
        "stock": {
            "quantity": 50
        }
    },
    {
        "id": 3,
        "sku": "SKU003",
        "name": "Product 3",
        "description": "Description for Product 3",
        "images": "https://offspring.co.ke/wp-content/uploads/2021/05/huggies-little-swimmers-disposable-swim-pants-421238_2048x2048-700x700.jpg",
        "has_variations": false,
        "variations": null,
        "has_varying_price": false,
        "price": {
            "default": 49.99
        },
        "sub_category_id": 2,
        "published": true,
        "created_at": "2024-09-03T00:00:00Z",
        "updated_at": "2024-09-03T00:00:00Z",
        "deleted_at": null,
        "stock": {
            "quantity": 75
        }
    },
    {
        "id": 4,
        "sku": "SKU004",
        "name": "Product 4",
        "description": "Description for Product 4",
        "images": "https://offspring.co.ke/wp-content/uploads/2021/05/huggies-little-swimmers-disposable-swim-pants-421238_2048x2048-700x700.jpg",
        "has_variations": false,
        "variations": null,
        "has_varying_price": false,
        "price": {
            "default": 59.99
        },
        "sub_category_id": 2,
        "published": true,
        "created_at": "2024-09-04T00:00:00Z",
        "updated_at": "2024-09-04T00:00:00Z",
        "deleted_at": null,
        "stock": {
            "quantity": 30
        }
    },
    {
        "id": 5,
        "sku": "SKU005",
        "name": "Product 5",
        "description": "Description for Product 5",
        "images": "https://offspring.co.ke/wp-content/uploads/2021/05/huggies-little-swimmers-disposable-swim-pants-421238_2048x2048-700x700.jpg",
        "has_variations": false,
        "variations": null,
        "has_varying_price": false,
        "price": {
            "default": 69.99
        },
        "sub_category_id": 3,
        "published": true,
        "created_at": "2024-09-05T00:00:00Z",
        "updated_at": "2024-09-05T00:00:00Z",
        "deleted_at": null,
        "stock": {
            "quantity": 20
        }
    }
];

const ProductsList: React.FC = () => {
    const [products] = useState<Product[]>(dummyProducts);
    const [loading] = useState<boolean>(false);
    const [error] = useState<string | null>(null);

    // Uncomment this block if you want to simulate fetching data
    /*
    useEffect(() => {
        setLoading(true);
        // Simulate an API call
        setTimeout(() => {
            setProducts(dummyProducts);
            setLoading(false);
        }, 1000);
    }, []);
    */

    if (loading) {
        return <p className="container mx-auto">Loading products...</p>;
    }

    if (error) {
        return <p className="container mx-auto">Error: {error}</p>;
    }

    return (
        <section className="container mx-auto">
            <div className="text-center py-4 mt-8">
                <h1 className="text-3xl font-bold mb-8">New Arrivals</h1>
                <div className="grid grid-cols-2 gap-4 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-5">
                    {products.map((product) => (
                        <ProductCard key={product.id} product={product} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default ProductsList;
